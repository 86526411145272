<template>
  <div class="list-view">
    <page-title>
      <el-button
        @click="addRow"
        type="primary"
        icon="el-icon-plus"
        :disabled="fetchingData || downloading"
        class="filter-item"
      >
        新增
      </el-button>
      <!-- <el-button
                @click="download"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button> -->
    </page-title>
   <!-- <div class="filters-container">
      <el-input
        placeholder="搜索..."
        v-model="search"
        clearable
        class="filter-item search"
        @keyup.enter.native="initData"
      >
        <el-button
          @click="initData"
          slot="append"
          icon="el-icon-search"
        > </el-button>
      </el-input>
    </div> -->
    <el-table
      :data="tableData"
      row-key="id"
      ref="table"
      header-row-class-name="table-header-row"
      header-cell-class-name="table-header-cell"
      row-class-name="table-row"
      cell-class-name="table-cell"
      :height="tableHeight"
      v-loading="fetchingData"
    >
      <el-table-column
        v-if="multipleMode"
        align="center"
        type="selection"
        width="50"
      > </el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        width="100"
      > </el-table-column>
      <el-table-column
        prop="name"
        label="分类名称"

        show-overflow-tooltip
      > </el-table-column>
        <el-table-column prop="pic" label="图片" >
            <template slot-scope="{ row }">
                <el-image
                    style="width: 30px; height: 30px"
                    :src="row.pic"
                    fit="cover"
                    :preview-src-list="[row.pic]"
                ></el-image>
            </template>
        </el-table-column>
        <el-table-column
            prop="total"
            label="分类排序"
            show-overflow-tooltip

        ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"

      >

        <template slot-scope="{ row }">
            <el-button
                v-if="row.parentId == 0"
                @click="addChild(row)"
                type="primary"
                size="mini"
                plain
            >新增二级</el-button>
          <el-button
            @click="editRow(row)"
            type="primary"
            size="mini"
            plain
          >查看</el-button>
            <el-button @click="deleteCollection(row)" type="danger" size="mini" plain>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
<!--    <div class="pagination-wrapper">
      <el-pagination
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalElements"
      >
      </el-pagination>
    </div> -->

  </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import SortableHeader from '../components/SortableHeader.vue';

export default {
    components: { SortableHeader },
    name: 'CollectionClassList',
    // mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            childDialog: false,
            fetchingData: false,
            search: '',
            url: '/collectionCategory/all',
            tableData: [],
            downloading: false,
            sort: { sort: 'desc' },
            sortStr: 'sort,desc',
            createdAt: '',
            minterId: ''
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    created() {
        this.getList()
    },
    methods: {
        deleteCollection(row) {
            if(row.children.length > 0) {
                this.$message.error("请先删除子分类！");
                return
            }
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/collectionCategoryCard/delCategory/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getList();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        },
        typeFormatter(row, column, cellValue, index) {
            let selectedOption = this.typeOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        sourceFormatter(row, column, cellValue, index) {
            let selectedOption = this.sourceOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        beforeGetData() {
            return {
                sort: 'createdAt,desc',
                search: this.search,
                query: { del: false, source: 'OFFICIAL', createdAt: this.createdAt, minterId: this.minterId }
            };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        getList() {
            this.$http.post('collectionCategoryCard/all', this.params, {
                body: 'json'
            }).then(res=>{
                this.tableData = res
            })
        },
        addRow() {
            this.$router.push({
                path: '/brandEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        addChild(row) {
            this.$router.push({
                path: '/brandEdit',
                query: {
                    parentId: row.id
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/brandEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/collection/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/collection/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
</style>
